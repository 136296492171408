import React from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import SendMessageToServerCall from "../../components/app/server/SendMessageToServerCall";

const SearchScreen = () => {
    return (
        <TelegramScreen showbackbutton={true}>
            <TelegramHeader>
                <TelegramText className={'telegramTitle'}>Recherche de PDF</TelegramText>
            </TelegramHeader>

            <SendMessageToServerCall />
        </TelegramScreen>
    );
};

export default SearchScreen;
