import './App.css';
import {useCallback, useEffect, useState} from "react";
import {useTelegram} from "./hooks/useTelegram";
import {Route, Routes, useNavigate} from "react-router-dom";
import Main from "./screens/main/MainScreen";
import {PATH_CATEGORIES, PATH_SEARCH} from "./constants/Paths";
import SearchScreen from "./screens/server/SearchScreen";
import CategoriesScreen from "./screens/server/CategoriesScreen";
import CategoryScreen from "./screens/server/CategorieScreen";
import SearchResultScreen from "./screens/server/SearchResultScreen";
import SousCategorieScreen from "./screens/server/SousCategorieScreen";


function App() {
    const [enabled, setEnabled] = useState(false);
    const {webApp, user} = useTelegram()
    const navigate = useNavigate();

    const onBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const onMainClick = useCallback(() => {
        webApp.showAlert("Main button click")
    }, [webApp])

    useEffect(() => {
        webApp.ready()
        webApp.BackButton.onClick(onBackClick)
        webApp.MainButton.onClick(onMainClick)
        return () => {
            webApp.BackButton.offClick(onBackClick)
            webApp.MainButton.offClick(onMainClick)
        };
    }, [webApp]);

    useEffect(() => {
        fetch( `https://telegram.juanito.pro/api/access/` + user?.id, {method: 'GET'}).then((res) => res.json()).then((res) => {
            if (res.access) {
                setEnabled(true)
            }
        })
    }, [user])
    return enabled ? (<div className="App">
            <Routes>
                <Route index element={<Main/>}/>
                <Route path={PATH_SEARCH} element={<SearchScreen/>}/>
                <Route path={PATH_SEARCH+"/:search"} element={<SearchResultScreen />} />
                <Route path={PATH_CATEGORIES} element={<CategoriesScreen/>}/>
                <Route path={PATH_CATEGORIES+"/:catId/:catName"} element={<CategoryScreen />} />
                <Route path={PATH_CATEGORIES+"/:catId/:catName/:sousCatId/:sousCatName"} element={<SousCategorieScreen />} />
            </Routes>
        <span style={{position: 'absolute', bottom: 10, left: 10}}>by Juanito</span>
    </div>) : <span></span>
}

export default App;
