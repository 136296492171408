import React, {useEffect, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import {useParams} from "react-router-dom";
import {downloadFile, getFile, searchPDF} from "../../logic/server/HttpClient";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import {useTelegram} from "../../hooks/useTelegram";
import pdf from '../../assets/pdf.png'
import folder from "../../assets/folder.png";

const SearchResultScreen =  () => {
    const { search } = useParams();
    const {webApp, user} = useTelegram()
    const [files, setFiles] = useState([]);
    useEffect(() => {
        searchPDF(search)
            .then((r) => r.json())
            .then(({files}) => {
                setFiles(files);
            });
    }, []);
    return (<TelegramScreen showbackbutton={true}>
        <TelegramHeader>
            <TelegramText className={'telegramTitle'}>Recherche: {search}</TelegramText>
        </TelegramHeader>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', maxWidth: '100vw'}}>
            {files.map((c) => <TelegramButton onClick={() => {
                downloadFile(c.id, user.id);
                webApp.showAlert("Je viens de t'envoyer le fichier " + c.name);
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '1rem',
                    margin: 'auto'
                }}>
                    <img src={`https://telegram.juanito.pro/thumb/${c.uuid}.jpg`} width={100}/>
                    <span>{c.name.replaceAll("_", " ").replaceAll(".pdf", "")}</span>
                </div>
            </TelegramButton>)}
        </div>
    </TelegramScreen>)

};

export default SearchResultScreen;
