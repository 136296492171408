export function useTelegram() {
    const webApp = window.Telegram.WebApp
    const user = webApp.initDataUnsafe?.user

    const onArgumentResult = (functionName, argument, result) => {
        webApp.showAlert(`${functionName}(${argument}) returned ${result}`)
    }

    const onResult = (functionName, result) => {
        onArgumentResult(functionName, '', result)
    }

    const onReceivedEvent = (event, data) => {
        webApp.showAlert(`received event(${event}) with data(${data})`)
    }

    const executeArgumentMethod = (methodName, argument, method, ignoreAlert) => {
        try {
            const result = method()
            if (!ignoreAlert) {
                const wrappedResult = `Result: ${result}`
                onArgumentResult(methodName, argument, wrappedResult)
            }
        } catch (error) {
            onArgumentResult(methodName, argument, error)
        }
    }

    const executeMethod = (methodName, method, ignoreAlert) => {
        executeArgumentMethod(methodName, '', method, ignoreAlert)
    }

    return {
        webApp,
        user,
        onArgumentResult,
        onResult,
        onReceivedEvent,
        executeArgumentMethod,
        executeMethod
    }
}
