import React from 'react';
import TelegramMiniForm from "../../kit/MiniForm/TelegramMiniForm";
import {useTelegram} from "../../../hooks/useTelegram";
import {searchPDF} from "../../../logic/server/HttpClient";
import {useNavigate} from "react-router-dom";
import {PATH_SEARCH} from "../../../constants/Paths";

const SendMessageToServerCall = () => {
    const {webApp} = useTelegram()
    const navigate = useNavigate();
    const queryId = webApp.initDataUnsafe?.query_id


    const onSendMessage = async (message) => {
        if (message && message.length > 0) {
            navigate(PATH_SEARCH +"/" + message);
        }
    }

    return (
        <TelegramMiniForm
            fieldlabel={'Quel PDF cherchez vous ?'}
            fieldhint={`Nom du PDF`}
            buttonlabel={'Rechercher'}
            onSubmit={onSendMessage}
        />
    );
};

export default SendMessageToServerCall;

