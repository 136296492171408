import React, {useEffect, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import {useNavigate, useParams} from "react-router-dom";
import {downloadFile, getFile} from "../../logic/server/HttpClient";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import {PATH_CATEGORIES} from "../../constants/Paths";
import {useTelegram} from "../../hooks/useTelegram";
import folder from '../../assets/folder.png'

const CategoryScreen =  () => {
    const { catId, catName } = useParams();
    const navigate = useNavigate();
    const {webApp, user} = useTelegram()
    const [files, setFiles] = useState([]);
    const [sousCat, setSousCat] = useState([]);

    useEffect(() => {
        getFile(catId)
            .then((r) => r.json())
            .then(({files, sousCat}) => {
                setFiles(files)
                setSousCat(sousCat);
            });
    }, []);
    return (<TelegramScreen showbackbutton={true}>
        <TelegramHeader>
            <TelegramText className={'telegramTitle'}>Categorie: {catName}</TelegramText>
        </TelegramHeader>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
            {sousCat.map((c) => <TelegramButton onClick={() => {
                navigate(PATH_CATEGORIES + "/" + catId + "/" + catName  + "/" + c.id + "/" + c.name);
            }}>
               <div style={{display: 'flex', alignItems: 'center', gap: '1rem', margin: 'auto'}}>
                   <img src={folder} width={20}/>
                   {c.name}
               </div>
            </TelegramButton>)}
            {files.map((c) => <TelegramButton onClick={() => {
                webApp.showAlert("Je viens de t'envoyer le fichier " + c.name);
                downloadFile(c.id, user.id);
            }}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', margin: 'auto'}}>
                    <img src={`https://telegram.juanito.pro/thumb/${c.uuid}.jpg`} width={100}/>
                    <span>{c.name.replaceAll("_", " ").replaceAll(".pdf", "")}</span>
                </div>
            </TelegramButton>)}
        </div>
    </TelegramScreen>)

};

export default CategoryScreen;
