import React, {useEffect} from 'react';
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import {useTelegram} from "../../hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import {PATH_CATEGORIES, PATH_SEARCH, PATH_SERVER} from "../../constants/Paths";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramDetailedButton from "../../components/kit/DetailedButton/TelegramDetailedButton";

import pdf from '../../assets/pdf.png'
const Main = () => {
    const {user} = useTelegram()
    const navigate = useNavigate();

    return (
        <TelegramScreen showbackbutton={false}>
            <TelegramHeader>
                <div style={{display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                    <img width={50} height={50} src={pdf}></img>
                    <TelegramText className={'telegramTitle'}>PDF Mini App</TelegramText>
                </div>
            </TelegramHeader>
            <br/>
            <TelegramText>Bonjour {user?.username}!</TelegramText>
            <div style={{display: 'flex'}}>
                <TelegramDetailedButton
                    buttonlabel={'Naviguer dans les categories'}
                    onButtomClick={() => navigate(PATH_CATEGORIES)}
                />
                <TelegramDetailedButton
                    buttonlabel={'Faire une recherche'}
                    onButtomClick={() => navigate(PATH_SEARCH)}
                />
            </div>
        </TelegramScreen>
    );
};

export default Main;
