import React, {useEffect, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import {getCategories} from "../../logic/server/HttpClient";
import {useNavigate} from "react-router-dom";
import {PATH_CATEGORIES} from "../../constants/Paths";
import folder from '../../assets/folder.png'
import pdf from "../../assets/pdf.png";

const CategoriesScreen =  () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    useEffect(() => {
         getCategories()
            .then((r) => r.json())
            .then(({categories}) => setCategories(categories));
    }, []);
    return (<TelegramScreen showbackbutton={true}>
        <TelegramHeader>
            <TelegramText className={'telegramTitle'}>Liste des categories</TelegramText>
        </TelegramHeader>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
            {categories.map((c) => <TelegramButton onClick={() => navigate(PATH_CATEGORIES + "/" + c.id + "/" + c.Titre)}>
                <div style={{display: 'flex', alignItems: 'center', gap: '1rem', margin: 'auto'}}>
                    <img src={folder} width={20}/>
                    {c.Titre}
                </div>
            </TelegramButton>)}
        </div>
    </TelegramScreen>)

};

export default CategoriesScreen;
