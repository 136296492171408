export const searchPDF = async (message) => {
    const body = {
        name: message
    }

    return await fetch( `https://telegram.juanito.pro/api/search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}

export const getCategories = async () => {
    return await fetch(`https://telegram.juanito.pro/api/categories`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const getFile = async (category) => {
    return await fetch(`https://telegram.juanito.pro/api/categories/${category}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const getFileBySousCat = async (category, sousCat) => {
    return await fetch(`https://telegram.juanito.pro/api/categories/${category}/${sousCat}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}


export const downloadFile = async (file, user) => {
    return await fetch(`https://telegram.juanito.pro/api/download/${file}/${user}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
